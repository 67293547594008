.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.flowchartParent,
.pageStructure {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flowchartParent {
  align-self: stretch;
  max-width: 100%;
  flex-shrink: 0;
}
.pageStructure {
  width: 100%;
  height: 366.056rem;
  position: relative;
  padding: 0 0 45.243rem;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1250px) {
  .pageStructure {
    height: auto;
  }
}


.logoIcon {
  height: 1.688rem;
  width: 3.938rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.column {
  width: 26.813rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.link,
.link1 {
  display: inline-block;
}
.link {
  position: relative;
  line-height: 150%;
  min-width: 2.75rem;
}
.link1 {
  min-width: 2.688rem;
}
.link1,
.link2,
.link3 {
  position: relative;
  line-height: 150%;
}
.link2 {
  align-self: stretch;
  width: 3.813rem;
  display: none;
}
.link3 {
  display: inline-block;
  min-width: 3.813rem;
}
.chevronDownIcon {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5rem;
}
.column1,
.navLinkDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
}
.column1 {
  width: 15rem;
  overflow: hidden;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.button1 {
  position: relative;
  font-size: var(--text-regular-normal-size);
  line-height: 150%;
  font-family: var(--text-regular-normal);
  color: var(--white);
  text-align: left;
  display: inline-block;
  min-width: 4.813rem;
}
.button {
  cursor: pointer;
  border: 1px solid var(--black);
  padding: var(--padding-7xs) var(--padding-lgi);
  background-color: var(--black);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.button:hover {
  background-color: var(--color-darkslategray-100);
  border: 1px solid var(--color-darkslategray-100);
  box-sizing: border-box;
}
.column2,
.container,
.navbar2 {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.column2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
}
.container,
.navbar2 {
  align-self: stretch;
  align-items: center;
}
.container {
  flex-direction: row;
  gap: var(--gap-13xl);
}
.navbar2 {
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-45xl) var(--padding-sm);
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: left;
  font-size: var(--text-regular-normal-size);
  color: var(--black);
  font-family: var(--text-regular-normal);
}
@media screen and (max-width: 1250px) {
  .column1 {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .container {
    gap: var(--gap-base);
  }
  .navbar2 {
    padding-left: var(--padding-13xl);
    padding-right: var(--padding-13xl);
    box-sizing: border-box;
  }
}


.starIcon,
.starIcon1,
.starIcon2,
.starIcon3,
.starIcon4 {
  height: 1.181rem;
  width: 1.25rem;
  position: relative;
  min-height: 1.188rem;
}
.stars {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.quote {
  width: 22rem;
  position: relative;
  line-height: 150%;
  display: inline-block;
  max-width: 100%;
}
.avatarImageIcon {
  height: 3.5rem;
  width: 3.5rem;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}
.reviewerName {
  font-weight: 600;
  display: inline-block;
  min-width: 7rem;
}
.reviewerName,
.reviewerPosition {
  position: relative;
  line-height: 150%;
}
.avatar,
.avatarContent,
.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.avatar,
.column {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-base);
  font-size: var(--text-regular-normal-size);
}
.column {
  flex: 1;
  border: 1px solid var(--black);
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-11xl) var(--padding-xl) var(--padding-11xl)
    var(--padding-13xl);
  gap: var(--gap-13xl);
  min-width: 19.5rem;
  max-width: 100%;
  text-align: left;
  font-size: var(--text-medium-normal-size);
  color: var(--black);
  font-family: var(--text-regular-normal);
}
@media screen and (max-width: 450px) {
  .column {
    gap: var(--gap-base);
  }
}
